import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';

const unauthorizedCode = [401, 403]

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
})

// Config
const TOKEN_PAYLOAD_KEY = 'Authorization'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
	
	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
	}

  	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	return response
}, (error) => {

	let notificationParam = {
		message: ''
	}
 
	// Remove token and redirect 
	if (unauthorizedCode.includes(error.response.status)) {
		notificationParam.message = 'Authentication Fail'
		notificationParam.description = 'Please login again'
		localStorage.removeItem(AUTH_TOKEN)
		store.dispatch(signOutSuccess())
	}
	if (error.response.status === 400) {
		notificationParam.message = 'Bad Request'
		// if data mssage is an array, then render it as a list
		if (Array.isArray(error.response.data.message)) {
			notificationParam.description = (
				<ul style={{ 
					listStyle: 'none',
					margin: 0,
					padding: 0,
				 }}>

				
					{error.response.data.message.map((item, index) => (
						<li key={index}>{item}</li>
					))}
				</ul>
			)
		} else {
			notificationParam.description = error.response.data.message
		}
		
	}

	if (error.response.status === 404) {
		notificationParam.message = 'Not Found'
	}

	if (error.response.status === 500) {
		notificationParam.message = 'Internal Server Error'
	}
	
	if (error.response.status === 508) {
		notificationParam.message = 'Time Out'
	}
	if (error.response.status === 409) {
		notificationParam.message = error.response.data?.message || error.response.message
	}
	

	notification.error(notificationParam)

	return Promise.reject(error);
});

export default service